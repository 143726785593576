/**
 * @author Eric Carroll
 */
import { $, _, moment, Model, Collection } from 'okta';
import AppConfigurationValidations from '../utils/AppConfigurationValidations';
import AppConfigurationValidator from '../utils/AppConfigurationValidator';
import AppStatus from '../utils/AppStatus';
import DateUtils from '../utils/DateUtils';
import ModelCompletion from '../utils/ModelCompletion';
import ModelUtils from '../utils/ModelUtils';
import SimpleValidators from '../utils/SimpleValidators';
import { getSectionName } from './appConfiguration/Section';
import AppConfigurationProps from './AppConfigurationProps';
import AppProps from './AppProps';
import OauthConfigurationProps from './OauthConfigurationProps';
import OidcConfigurationProps from './OidcConfigurationProps';
import SamlConfigurationProps from './SamlConfigurationProps';
import ScimConfigurationProps from './ScimConfigurationProps';
import { getConfig } from '../utils/Config';

const template = ({ section, displayName }) => `${getSectionName(section)} - ${displayName}`;

const props = _.extend(
  {
    // pointer to the next/prev version, if there is one
    nextVersionId: 'number',
    prevVersionId: 'number',

    // app, company info
    'app.id': 'number',
    'app.company.name': 'string',
    'app.company.linkedOrgUrl': 'string',
    'app.company.orgId': 'string',
  },
  AppProps.getProps(),
  AppConfigurationProps.getProps(),
  SamlConfigurationProps.getProps(),
  ScimConfigurationProps.getProps(),
  OidcConfigurationProps.getProps(),
  OauthConfigurationProps.getProps(),
);

// extend unique props with common app, and application and integration type configuration props

const showErrors = _.debounce((model, errors) => {
  model.trigger('form:clear-errors');
  model.trigger('invalid', model, errors);
}, 500);

const clearErrors = _.debounce((model) => {
  model.trigger('form:clear-errors');
}, 500);

const priorityLabels = {
  NONE: 'None',
  DISCARD: 'Discard',
  ONE: '1',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  FIVE: '5',
};

const AppConfiguration = Model.extend({
  urlRoot: '/api/v1/app-configurations',

  validate() {
    const appConfigValidator = new AppConfigurationValidator(this);
    return appConfigValidator.validate();
  },

  /**
   * Submit the form
   * @param opts Options passed to the submit action
   * @returns {*} Promise to do the submit
   */
  submit(opts) {
    // double-check this is legal
    if (!this.allComplete() || !this.get('isNotSubmitted')) {
      throw new Error('Cannot submit a form until it is 100% complete');
    }

    opts.data = JSON.stringify(this);
    opts.contentType = 'application/json';

    // do it
    return ModelUtils.doAction(this, 'PUT', 'submit', opts, true);
  },

  /**
   * Cancel the submission
   * @param opts Options passed to the cancel action
   * @returns {*} Promise to do the cancel
   */
  cancel(opts) {
    // double-check this is legal
    if (!(this.get('isSubmitted') || this.get('isDeprecated'))) {
      throw new Error('Cannot cancel a non-submitted form');
    }

    // do it
    return ModelUtils.doAction(this, 'PUT', 'cancel', opts, true);
  },

  /**
   * Edit a published submission
   * @param opts Options passed to the edit-published action
   * @returns {*} Promise to do the edit-publish
   */
  editPublished(opts) {
    // double-check this is legal - could still fail if another edit exists
    if (!this.get('isPublished')) {
      throw new Error('Cannot edit this form, it is not published');
    }

    // do it
    return ModelUtils.doAction(this, 'POST', 'edit_published', opts, false);
  },

  publishToAcv2() {
    const deferred = $.Deferred();
    const self = this;
    const categoryList = self.get('appConfigurationJson.appInfo.notInOan.categoryList') || [];
    const supportContacts = self.get('appConfigurationJson.customerSupport.publicSupportContacts') || [];
    $.ajax({
      url: '/acv2/publish',
      contentType: 'application/json',
      dataType: 'json',
      type: 'POST',
      data: JSON.stringify({
        appGlobalId: self.get('globalId'),
        appCategories: categoryList,
        supportContacts: supportContacts,
      }),
    })
      .done(deferred.resolve)
      .fail((err) => {
        showErrors(this, err);
        deferred.reject(err);
      });
    return deferred.promise();
  },

  props: props,

  derived: {
    statusLabel: {
      deps: ['version', 'isResubmission'],
      fn(version, isResubmission) {
        let label = 'Version ' + version;

        if (isResubmission) {
          label += ', Edited';
        }
        return label;
      },
    },
    updatedDate: {
      deps: ['updatedAt'],
      fn(updatedAt) {
        return DateUtils.getDate(updatedAt, 'America/Los_Angeles', 'MM/DD/YY');
      },
    },
    dueDate: {
      deps: ['dueAt'],
      fn(dueAt) {
        return DateUtils.getDate(dueAt, 'America/Los_Angeles', 'MM/DD/YY');
      },
    },
    submittedDate: {
      deps: ['submittedAt'],
      fn(submittedAt) {
        return DateUtils.getDate(submittedAt, 'America/Los_Angeles', 'MM/DD/YY');
      },
    },
    publishedDate: {
      deps: ['publishedAt'],
      fn(publishedAt) {
        return DateUtils.getDate(publishedAt, 'America/Los_Angeles', 'MM/DD/YY');
      },
    },
    pastDue: {
      deps: ['dueAt'],
      fn(dueAt) {
        return dueAt && moment(new Date()).isAfter(dueAt);
      },
    },
    staleState: {
      deps: ['status', 'updatedAt'],
      fn(status, updatedAt) {
        return AppStatus.isUnderIsvReview(status) && moment(new Date()).subtract(14, 'day').isAfter(updatedAt);
      },
    },
    isReadOnly: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isPublished(status) || AppStatus.isUnderReview(status) || AppStatus.isDeprecated();
      },
    },
    isSubmitted: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isUnderReview(status);
      },
    },
    isNotSubmitted: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isInProgress(status);
      },
    },
    isPublished: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isPublished(status);
      },
    },
    isDeprecated: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isDeprecated(status);
      },
    },
    stepLabel: {
      deps: ['status', 'version'],
      fn(status, version) {
        if (status === 'NOT_SUBMITTED') {
          status += version === 1 ? '_NEW' : '_FORK';
        }
        return AppStatus.getStepLabel(status);
      },
    },
    dashboardLabelUpper: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getDashboardStatus(status, true);
      },
    },
    dashboardTitle: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getDashboardTitle(status);
      },
    },
    dashboardColor: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getColor(status);
      },
    },
    appNameLabel: {
      deps: ['appName'],
      fn(appName) {
        return $.trim(appName) || 'Unnamed App';
      },
    },
    priorityLabel: {
      deps: ['priority'],
      fn(priority) {
        return priorityLabels[priority];
      },
    },
    samlAppInstanceProperties: {
      deps: ['samlConfigurationJson.appInstanceProperties', 'samlConfigurationJson.sloAppInstanceProperties'],
      fn(aips, sloAips) {
        return _.union(aips, sloAips);
      },
    },
    scimAppInstanceProperties: {
      deps: ['scimConfigurationJson.appInstanceProperties'],
      fn(aips) {
        return aips;
      },
    },
    oidcAppInstanceProperties: {
      deps: [
        'oidcConfigurationJson.redirectUriAips',
        'oidcConfigurationJson.initiateLoginUriAips',
        'oidcConfigurationJson.postLogoutRedirectUriAips',
      ],
      fn(redirectUriAips, initiateLoginUriAips, postLogoutRedirectUriAips) {
        return _.union(redirectUriAips, initiateLoginUriAips, postLogoutRedirectUriAips);
      },
    },
  },

  /**
   * The following functions compute the percent completion for various sections
   *
   * Each .add() counts as a unit in the percent, meaning existsInOan and the fields that it toggles on and off
   * are all considered one unit of completion.  Did this so the user doesn't see the completion percent drop
   * when they make a selection that toggles in a handful of new fields.
   */
  completionPercentByType(type) {
    const completionMap = {
      SCIM: this.completionPercentSCIM,
      SAML: this.completionPercentSAML,
      OIDC: this.completionPercentOIDC,
      OAUTH: this.completionPercentOAuth,
      CONNECTOR: this.completionPercentConnector,
    };
    return completionMap[type].call(this);
  },

  completionPercentGeneralForTestInOkta() {
    return (
      new ModelCompletion(this)
        // app information - must either fill in the YES -or- NO fields, based on if it exists in the OIN/OAN
        .add({
          branchField: 'appConfigurationJson.appInfo.existsInOan',
          branches: {
            YES: ['appConfigurationJson.appInfo.inOan.name', 'appConfigurationJson.appInfo.updatedInfo'],
            NO: 'appConfigurationJson.appInfo.notInOan.name',
          },
        })

        .add('appConfigurationJson.appInfo.notInOan.icon')
        .getPercent()
    );

  },

  completionPercentGeneral() {
    return (
      new ModelCompletion(this)
        // app information - must either fill in the YES -or- NO fields, based on if it exists in the OAN
        .add({
          branchField: 'appConfigurationJson.appInfo.existsInOan',
          branches: {
            YES: ['appConfigurationJson.appInfo.inOan.name', 'appConfigurationJson.appInfo.updatedInfo'],
            NO: 'appConfigurationJson.appInfo.notInOan.name',
          },
        })

        // app info - these used to be only for not-in-oan, but are now always asked for
        .add('appConfigurationJson.appInfo.notInOan.website')
        .add('appConfigurationJson.appInfo.notInOan.description')
        .add('appConfigurationJson.appInfo.notInOan.icon')

        // customer support - all required
        .add((model) => {
          const supportContacts = model.get('appConfigurationJson.customerSupport.publicSupportContacts');
          return supportContacts.every((contact) => contact.data);
        })
        .add('appConfigurationJson.customerSupport.escalationSupportContact')

        // test account - can either fill in the url, user and password -or- instructions
        .add({
          any: [
            'appConfigurationJson.testAccount.instructions',
            [
              'appConfigurationJson.testAccount.url',
              'appConfigurationJson.testAccount.user',
              'appConfigurationJson.testAccount.password',
            ],
          ],
        })

        // done!
        .getPercent()
    );
  },

  completionPercentSCIM() {
    return (
      new ModelCompletion(this)

        // scim configuration - all required, other than demo video link and provisioning features
        .add('scimConfigurationJson.version')
        .add('scimConfigurationJson.fixedUrl')
        .add('scimConfigurationJson.supportsHttpPatch')
        .add('scimConfigurationJson.appInstanceUrl')
        .add('scimConfigurationJson.userNameFollowsEmailFormat')
        .add('scimConfigurationJson.runscopeTestLink')
        .add('scimConfigurationJson.runscopeCrudTestLink')

        // originally on general settings, moved to SCIM
        .add('appConfigurationJson.customerSupport.configGuideLink')
        .add('scimConfigurationJson.mappingsRemoved')
        .add('scimConfigurationJson.customPatchBatchSize')
        .add('scimConfigurationJson.responseWithinTimeLimit')
        // at least one provisioning feature must be specified
        .add(
          {
            any: [
              'scimConfigurationJson.provisioningFeature.createUsers',
              'scimConfigurationJson.provisioningFeature.updateUserAttrs',
              'scimConfigurationJson.provisioningFeature.deactivateUsers',
              'scimConfigurationJson.provisioningFeature.importUsers',
              'scimConfigurationJson.provisioningFeature.importGroups',
              'scimConfigurationJson.provisioningFeature.profileMaster',
              'scimConfigurationJson.provisioningFeature.syncPassword',
              'scimConfigurationJson.provisioningFeature.groupPush',
            ],
          },
          { falseMeansNotSet: true },
        )

        // authentication mode - must pick a mode and fill in the corresponding fields - note that 'BASIC' has no
        // dependent fields - note that HEADER.OTHER causes yet another required field to be added
        .add({
          branchField: 'scimConfigurationJson.authenticationMode',
          branches: {
            OAUTH_2: [
              'scimConfigurationJson.oauth2.tokenEndpoint',
              'scimConfigurationJson.oauth2.authorizeEndpoint',
              'scimConfigurationJson.oauth2.consumerKey',
              'scimConfigurationJson.oauth2.consumerSecret',
            ],
            HEADER: {
              branchField: 'scimConfigurationJson.header.tokenFormat',
              branches: { OTHER: 'scimConfigurationJson.header.other' },
            },
          },
        })
        .add({
          branchField: 'scimConfigurationJson.doesFixedUrlVaryByTenant',
          branches: {
            YES: ['scimConfigurationJson.appInstanceProperties', 'scimConfigurationJson.fixedUrl'],
            NO: ['scimConfigurationJson.fixedUrl'],
          },
        })

        // done!
        .getPercent()
    );
  },

  completionPercentSAML() {
    return (
      new ModelCompletion(this)
        // big bang - if YES must also fill in the backdoor URL
        .add({
          branchField: 'samlConfigurationJson.bigBang.isBigBang',
          branches: {
            YES: 'samlConfigurationJson.bigBang.backdoorUrl',
            NO: [],
          },
        })

        // SAML identifier - if SAML Attribute, must also fill name of the attribute
        .add({
          branchField: 'samlConfigurationJson.samlIdentifier.type',
          branches: {
            OTHER: 'samlConfigurationJson.samlIdentifier.otherAttribute',
            NAME_ID: [],
          },
        })

        // encrypted assertion - if YES, must also fill in sameCert
        .add({
          branchField: 'samlConfigurationJson.encryptedAssertion.supported',
          branches: {
            YES: 'samlConfigurationJson.encryptedAssertion.sameCert',
            NO: [],
          },
        })

        // sp sso - if YES, must also fill in sameCert and sloVary
        .add({
          branchField: 'samlConfigurationJson.spSingleSignOut.supported',
          branches: {
            YES: [
              'samlConfigurationJson.spSingleSignOut.sameCert',
              'samlConfigurationJson.spSingleSignOut.doesSloUrlVaryPerTenant',
            ],
            NO: [],
          },
        })

        // force authentication - if YES, must also fill in howToConfigure and howToTest
        .add({
          branchField: 'samlConfigurationJson.forceAuthentication.supported',
          branches: {
            YES: [
              'samlConfigurationJson.forceAuthentication.howToConfigure',
              'samlConfigurationJson.forceAuthentication.howToTest',
            ],
            NO: [],
          },
        })

        // supportRelayState - if YES, must fill in defaultRelayState
        .add({
          branchField: 'samlConfigurationJson.supportRelayState',
          branches: {
            YES: 'samlConfigurationJson.defaultRelayState',
            NO: [],
          },
        })

        // doesAcsUrlVaryPerTenant - if YES, must fill in AIPs
        .add({
          branchField: 'samlConfigurationJson.doesAcsUrlVaryPerTenant',
          branches: {
            YES: [
              'samlConfigurationJson.appInstanceProperties',
              'samlConfigurationJson.acsUrl',
              'samlConfigurationJson.audienceUri',
            ],
            NO: [],
          },
        })

        // at least one sign-in flow must be specified
        .add(
          {
            any: ['samlConfigurationJson.signInFlow.idp', 'samlConfigurationJson.signInFlow.sp'],
          },
          {
            falseMeansNotSet: true,
          },
        )
        .add({
          branchField: 'samlConfigurationJson.signInFlow.sp',
          branches: {
            true: ['samlConfigurationJson.signInFlow.spInitiatedUrl', 'samlConfigurationJson.signInFlow.spDescription'],
            false: [],
          },
        })

        // the rest are text or radio button values
        .add('samlConfigurationJson.appInstanceUrl')
        .add('samlConfigurationJson.serviceType')
        .add('samlConfigurationJson.isOnPremise')
        .add('samlConfigurationJson.supportJit')
        .add('samlConfigurationJson.connectMultipleIdP')
        .add('samlConfigurationJson.configGuideLink')
        .add((model) => {
          const eaSupported = model.get('samlConfigurationJson.encryptedAssertion.supported');
          const eaSameCert = model.get('samlConfigurationJson.encryptedAssertion.sameCert');
          const eaCert = model.get('samlConfigurationJson.encryptedAssertion.cert');

          return eaSupported === 'NO' || eaSameCert === 'NO' || !_.isEmpty(eaCert);
        })
        .add((model) => {
          const sloSupported = model.get('samlConfigurationJson.spSingleSignOut.supported');
          const sloSameCert = model.get('samlConfigurationJson.spSingleSignOut.sameCert');
          const sloCert = model.get('samlConfigurationJson.spSingleSignOut.cert');

          return sloSupported === 'NO' || sloSameCert === 'NO' || !_.isEmpty(sloCert);
        })
        .add((model) => {
          const sloSupported = model.get('samlConfigurationJson.spSingleSignOut.supported');
          const doesSloVary = model.get('samlConfigurationJson.spSingleSignOut.doesSloUrlVaryPerTenant');
          const sloAips = model.get('samlConfigurationJson.sloAppInstanceProperties');
          const sloUrl = model.get('samlConfigurationJson.spSingleSignOut.sloUrl');
          return sloSupported === 'NO' || doesSloVary === 'NO' || (!_.isEmpty(sloUrl) && !_.isEmpty(sloAips));
        })

        // attribute statement is an array of statements
        .add((model) => {
          const urls = model.get('samlConfigurationJson.otherSingleSignOnUrl.requestableUrls');
          const supported = model.get('samlConfigurationJson.otherSingleSignOnUrl.supported');

          return (
            supported === 'NO' ||
            (supported === 'YES' && urls.length > 0 && !SimpleValidators.validRequestableSSOUrls(urls))
          );
        })
        // done!
        .getPercent()
    );
  },

  completionPercentOIDC() {
    return (
      new ModelCompletion(this)
        // the rest are text or radio button values
        .add('oidcConfigurationJson.appInstanceUrl')
        .add('oidcConfigurationJson.serviceType')
        .add('oidcConfigurationJson.connectMultipleIdP')
        .add('oidcConfigurationJson.platform')
        .add('oidcConfigurationJson.configGuideLink')

        // At least one sign-in flow must be specified
        .add(
          {
            any: ['oidcConfigurationJson.signInFlow.idp', 'oidcConfigurationJson.signInFlow.sp'],
          },
          {
            falseMeansNotSet: true,
          },
        )

        // At least one supported oidc mode must be specified
        .add(
          {
            any: ['oidcConfigurationJson.supportedOidcModes.web', 'oidcConfigurationJson.supportedOidcModes.spa'],
          },
          {
            falseMeansNotSet: true,
          },
        )

        // Redirect URI varies by tenant
        .add({
          branchField: 'oidcConfigurationJson.redirectUriVaryByTenant',
          branches: {
            YES: ['oidcConfigurationJson.redirectUriAips', 'oidcConfigurationJson.redirectURIs'],
            NO: ['oidcConfigurationJson.redirectURIs'],
          },
        })

        // Initiate Login URI - Required, No vary by tenant
        .add({
          branchField: 'oidcConfigurationJson.initiateLoginUriRequired',
          branches: {
            YES: ['oidcConfigurationJson.initiateLoginUriVaryByTenant'],
            NO: [],
          },
        })

        // Initiate Login URI - Vary by tenant and AIPs
        .add((model) => {
          const required = model.get('oidcConfigurationJson.initiateLoginUriRequired');
          const varyByTenant = model.get('oidcConfigurationJson.initiateLoginUriVaryByTenant');
          const aips = model.get('oidcConfigurationJson.initiateLoginUriAips');
          const uri = model.get('oidcConfigurationJson.initiateLoginURI');

          if (_.isUndefined(required)) {
            return false;
          } else if (required === 'NO') {
            return true;
          } else {
            // required is YES. We need to check varyByTenant
            if (_.isUndefined(varyByTenant)) {
              return false;
            } else if (varyByTenant === 'YES') {
              return !_.isEmpty(uri) && !_.isEmpty(aips);
            } else {
              return !_.isEmpty(uri);
            }
          }
        })

        // Post Logout Redirect URI - Required, No vary by tenant
        .add({
          branchField: 'oidcConfigurationJson.postLogoutRedirectUriRequired',
          branches: {
            YES: ['oidcConfigurationJson.postLogoutRedirectUriVaryByTenant'],
            NO: [],
          },
        })

        // Post Logout Redirect URI - Vary by tenant
        .add((model) => {
          const required = model.get('oidcConfigurationJson.postLogoutRedirectUriRequired');
          const varyByTenant = model.get('oidcConfigurationJson.postLogoutRedirectUriVaryByTenant');
          const aips = model.get('oidcConfigurationJson.postLogoutRedirectUriAips');
          const uris = model.get('oidcConfigurationJson.postLogoutRedirectURIs');

          if (_.isUndefined(required)) {
            return false;
          } else if (required === 'NO') {
            return true;
          } else {
            // required is YES. We need to check varyByTenant
            if (_.isUndefined(varyByTenant)) {
              return false;
            } else if (varyByTenant === 'YES') {
              return !_.isEmpty(uris) && !_.isEmpty(aips);
            } else {
              return !_.isEmpty(uris);
            }
          }
        })

        // done!
        .getPercent()
    );
  },

  completionPercentOAuthForTestInOkta() {
    return new ModelCompletion(this)
      .add('oauthConfigurationJson.scopes')
      .getPercent();
  },

  completionPercentOAuth() {
    return new ModelCompletion(this)
      .add('oauthConfigurationJson.configGuideLink')
      .add('oauthConfigurationJson.scopes')
      .getPercent();
  },

  completionPercentConnector() {
    return (
      new ModelCompletion(this)
        .add('connectorConfigurationMediated.connectorId')
        .add('connectorConfigurationMediated.connectorVersion')
        .add('connectorConfigurationMediated.testSuiteURL')
        .add('connectorConfigurationMediated.userDocumentURL')
        .add('connectorConfigurationMediated.apiDocumentURL')
        .getPercent()
    );
  },

  allComplete() {
    // general settings must be complete
    if (this.completionPercentGeneral() < 100) {
      return false;
    }

    // must be at least one integration type
    const integrationType = this.get('integrationType') || [];

    if (!integrationType.length) {
      return false;
    }

    const allowPartialCompletedSubmissions = getConfig('hideSsoTabsToDeprecateIsvPortal') === 'true';
    // each integration type must be complete
    for (let i = 0; i < integrationType.length; i++) {
      if (this.completionPercentByType(integrationType[i]) < 100 && !allowPartialCompletedSubmissions) {
        return false;
      } else if (this.completionPercentByType(integrationType[i]) < 100 && allowPartialCompletedSubmissions
          && integrationType[i] !== 'OIDC' && integrationType[i] !== 'SAML') {
        return false;
      }
    }

    // validate that there are no errors
    return this.validateAndShowErrors();
  },

  getFieldDisplayName(field) {
    const fieldValidations = AppConfigurationValidations.getValidations(field);

    return (fieldValidations && template(fieldValidations)) || field;
  },

  validateAndShowErrors() {
    const errors = this.validate();

    if (!_.isEmpty(errors)) {
      clearErrors.cancel && clearErrors.cancel();
      showErrors(this, errors);
      return false;
    } else {
      showErrors.cancel && showErrors.cancel();
      clearErrors(this);
      return true;
    }
  },
});
const AppConfigurations = Collection.extend({
  url: '/api/v1/app-configurations',
  model: AppConfiguration,
});
export default { Model: AppConfiguration, Collection: AppConfigurations };
