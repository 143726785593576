/**
 * @author Abbas Rattansi
 */
import { View } from 'okta';
export default View.extend({
  template:
    '<p class="torii-banner-content">Use the <a href="https://developer.okta.com/login">OIN Wizard</a> ' +
    'to submit an SSO (SAML or OIDC) integration. ' +
    'See <a href="https://developer.okta.com/docs/guides/submit-oin-app/openidconnect/main/">Submit an SSO ' +
    'integration with the OIN Wizard</a> for instructions.</p>',
});
